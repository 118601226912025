<template>
  <div>
    <b-modal
      id="modal-riwayat-tolak"
      size="xl"
      centered
      title="Riwayat Pelamar Kerja"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            stacked="md"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
          <template #cell(no)="item">
            {{item.index + 1}}
          </template>
          <template #cell(statusPostLoker)="item">
            <p v-if="item.item.statusPostLoker==0"> Aktif</p>
            <p v-if="item.item.statusPostLoker==1"> Tahap Kelengkapan Dokumen</p>
            <p v-if="item.item.statusPostLoker==2"> Tidak Lolos Kelengkapan Dokumen</p>
            <p v-if="item.item.statusPostLoker==3"> Tidak Lolos Tahapan {{item.item.namaTahapan}}</p>
            <p v-if="item.item.statusPostLoker==4"> Diterima</p>
          </template>
            <!-- <template #cell(actions)="item"> -->
              <!-- <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="(data = item.item), (edit = !edit)"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    > -->

              <!-- <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), (detail = !detail)"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    > -->

              <!-- <b-button
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Terima'"
                      v-b-modal.modal-acc
                      @click="data = item.item"
                      ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                    > -->

              <!-- <b-button
                variant="success"
                class="mr-1"
                v-c-tooltip.hover.click="'Terima'"
                v-b-modal.modal-detail-new
                @click="(data = item.item), (detail = !detail)"
                ><CIcon name="cil-check" /> {{ item.actions }}</b-button
              >

              <b-button
                variant="danger"
                class="mr-1"
                v-c-tooltip.hover.click="'Tolak'"
                v-b-modal.modal-tolak
                @click="data = item.item"
                ><CIcon name="cil-x" /> {{ item.actions }}</b-button
              >
              <b-button
                variant="info"
                class="mr-1"
                v-c-tooltip.hover.click="'Riwayat'"
                v-b-modal.modal-riwayat-tolak
                @click="data = item.item"
                ><CIcon name="cil-x" /> {{ item.actions }}</b-button
                
              > -->

            <!-- </template> -->
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-riwayat-tolak')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "modalEdit",
  props: ["data", "detail"],
  components: {
    Multiselect,
  },

  data() {
    return {
      busy: false,
      button: "Simpan",
      ipBackend,
      items: [
        {
          no: "-",
          pelamar: "-",
          alamat: "-",
          email: "-",
          posisiDilamar: "-",
          status: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaPengirim",
          label: "Nama Pelamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatPengirim",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "emailPengirim",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaLoker",
          label: "Posisi Yang Dilamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusPostLoker",
          label: "Status",
          class: "table-option-4 text-center",
        },
         {
          key: "keterangan",
          label: "Keterangan",
          class: "table-option-4 text-center",
        },

      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    data: {
      statusPostLoker: {
        required,
      },
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    async getRiwayat(){
        let vm = this
        let riwayat = await axios.post(ipBackend + "postLoker/listRiwayatDitolak", {
            nomorKTP: vm.data.nomorKTPPengirim
        } )
        console.log(riwayat.data.data,'histoti')
        vm.items = await riwayat.data.data.map(item=>{ 
          if(item.tahapan.length){
            return {...item, namaTahapan: item.tahapan[0].namaTahapan, keterangan: item.tahapan[0].keteranganPoolTahapan }
          }else{
            return item
          }
          
        })

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch:{
    detail: function(newVal, oldVal){
        if(oldVal != newVal){
            console.log(this.data)
            this.getRiwayat()
        }
    }
  }
};
</script>
