<template>
  <div>
    <b-modal
      id="modal-edit-ditolak"
      size="lg"
      centered
      title="Edit Data Pelamar Kerja (Ditolak)"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Nama Lengkap" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="data.namaPengirim"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="data.alamatPengirim"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="data.emailPengirim"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Posisi Yang Dilamar" label-cols-md="3">
          <b-form-input
            type="text"
            v-model="data.posisiDilamar"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label> File CV </template>
          <b-button variant="light" @click="view()">Lihat File</b-button>
        </b-form-group>

        <b-form-group label="Status" label-cols-md="3">
          <b-form-select
            :state="checkIfValid('statusPostLoker')"
            v-model="$v.data.statusPostLoker.$model"
            :options="status"
          ></b-form-select>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "modalEdit",
  props: ["loker", "data", "edit"],
  components: {
    Multiselect,
  },

  data() {
    return {
      busy: false,
      button: "Simpan",
      status: [
        { value: 0, text: "Silahkan Pilih" },
        { value: 2, text: "Ditolak" },
        { value: 1, text: "Diterima" },
      ],
      ipBackend,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      statusPostLoker: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    handleFile() {
      this.data.file1 = this.$refs.file1.files[0];
      this.src1 = URL.createObjectURL(this.data.file1);
    },
    view() {
      window.open(`${ipBackend}asset/pdf/${this.data.CV}`);
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.id = vm.data.postLokerId;
      axios
        .post(ipBackend + "postLoker/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN PELAMAR",
              showing: true,
            });
            this.data.namaPengirim = "";
            this.data.alamatPengirim = "";
            this.data.emailPengirim = "";
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
